import './App.css';

function App() {
  return (
    <div className="App">
      <p>collectivelabs.xyz</p>
      <p>Coming soon ...</p>
    </div>
  );
}

export default App;
